<template>
	<el-dialog title="导出搜索结果" :close-on-click-modal="false" width="300px" :visible.sync="is_show_in_page">
		<div style="text-align:center">总数量:{{max}} / 已读取:{{readed}}</div>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,//是否显示
			rull:Object,//搜索条件
            pay_excle_liet:Array,//勾选数据
            type:Number,
			ctr:String,
			mod:String,
			pay_status:''
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,
				
				//单次读取数量
				num:100,

				//总数量
				max:0,

				//已读取
				readed:0,

				//上次最后一个id
				id_max:false,

				p:1,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					this.max=0
					this.readed=0
					this.id_max=false
					this.start()
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//生成csv
			make_csv(){
				var shunxu=[
					{key:"id",cname:"系统id"},
					{key:"truck_tord_num",cname:"运单编号"},
					{key:"payed_num",cname:"支付编号"},
                    {key:"pay_status_text",cname:"支付状态"},
					{key:"creat_time_text",cname:"支付时间"},
					{key:"tord_creat_time_text",cname:"建单时间"},
					{key:"service_charge_text",cname:"服务费"},
					{key:"this_invoiced",cname:"开票额"},
					{key:"invoice_status_text",cname:"开票状态"},
                    {key:"case_addr",cname:"装货地"},
                    {key:"aim_addr",cname:"卸货地"},
                    {key:"driver_name",cname:"司机姓名"},
                    {key:"driver_tel",cname:"司机电话"},
                    {key:"truck_plate_num",cname:"车牌号"},
					{key:"this_settled_of_truck_owner",cname:"车队长收款"},
					{key:"truck_owner_bank_name",cname:"收款人"},
					{key:"truck_owner_bank_tel",cname:"收款人电话"},
					{key:"truck_owner_bank_cname",cname:"收款银行"},
					{key:"settle_status_of_truck_owner_text",cname:"车队长结算状态"},
					{key:"this_settled_of_driver",cname:"司机收款"},
					{key:"driver_bank_name",cname:"收款人"},
					{key:"driver_bank_tel",cname:"收款人电话"},
					{key:"driver_bank_cname",cname:"收款银行"},
					{key:"settle_status_of_driver_text",cname:"司机结算状态"},
				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<this.buf.length;i++){
					for(var a in shunxu){
						str+=`${this.buf[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "支付记录.csv";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
                this.$emit('close')
			},

			//读取
			read(){
				var p = this.p
				var pay_status = this.pay_status
				//读取数据
				this.$my.net.req({
					data:{
						mod:this.mod,
						ctr:this.ctr,
						is_get_truck_owner_info:true,
						is_get_driver_info:true,
						is_get_tord_info:true,
						is_get_cost_info:true,
						...this.rull,
						num:this.num,
						p:p,
						pay_status
					},callback:(data)=>{
						this.p ++
						if(data.list.length ==0){
							this.p = 1
							// this.read()
							this.make_csv()
							return
						}
                       //运单列表
							let tord_list={};

                            let exlce_pay_list=[]

							//预处理
							for(let item of data.list){
							   if(item.driver_bank_list.num ==1){
									item.driver_bank_name = item.driver_bank_list.list[0].card_user_name
									item.driver_bank_tel = item.driver_bank_list.list[0].card_user_tel
									item.driver_bank_cname = item.driver_bank_list.list[0].bank_cname
								}else{
									item.driver_bank_name = ''
									item.driver_bank_tel = ''
									item.driver_bank_cname = ''
								}
								if(item.truck_owner_bank_list.num ==1){
									item.truck_owner_bank_name = item.truck_owner_bank_list.list[0].card_user_name
									item.truck_owner_bank_tel = item.truck_owner_bank_list.list[0].card_user_tel
									item.truck_owner_bank_cname = item.truck_owner_bank_list.list[0].bank_cname
								}else{
									item.truck_owner_bank_name = ''
									item.truck_owner_bank_tel = ''
									item.truck_owner_bank_cname = ''
								}

								//支付时间
								item.creat_time_text=this.$my.other.totime(item.creat_time);
								//建单时间
								item.tord_creat_time_text=this.$my.other.totime(item.tord_info.creat_time);
								//本次支付类型(1:现金,2:燃油费,3:过路费)
								switch(item.this_pay_type){
									case '1':item.this_pay_type_text="现金";break;
									case '2':item.this_pay_type_text="燃油费";break;
									case '3':item.this_pay_type_text="过路费";break;
								}

								//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
								switch(item.pay_status){
									case '1':item.pay_status_text="未支付";break;
									case '2':item.pay_status_text="支付中";break;
									case '3':
										item.pay_status_text=`支付成功 ${this.$my.other.totime(item.payed_time)}`;
										break;
									case '4':
										item.pay_status_text=`支付失败 ${this.$my.other.totime(item.payed_time)}`;
										break;
									case '5':
										item.pay_status_text=`已撤销 ${this.$my.other.totime(item.pay_refused_time)}`;
										break;
								}
								
								//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
								switch(item.invoice_status){
									case '1':item.invoice_status_text="未申请";break;
									case '2':item.invoice_status_text="申请中";break;
									case '3':
										item.invoice_status_text=`开票成功 ${this.$my.other.totime(item.invoiced_time)}`;
										break;
									case '4':
										item.invoice_status_text=`开票失败 ${this.$my.other.totime(item.invoiced_time)}`;
										break;
								}
								var settled_time_of_truck_owner = ""
								if(item.settled_time_of_truck_owner !=0){
									settled_time_of_truck_owner = this.$my.other.totime(item.settled_time_of_truck_owner)
								}
								//结算给车队长的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
								switch(item.settle_status_of_truck_owner){
									case '1':item.settle_status_of_truck_owner_text="未申请";break;
									case '2':item.settle_status_of_truck_owner_text="待打款";break;
									case '3':
										item.settle_status_of_truck_owner_text=`打款成功 ${settled_time_of_truck_owner}`;
										break;
									case '4':
										item.settle_status_of_truck_owner_text=`打款失败 ${settled_time_of_truck_owner}`;
										break;
								}
								var settled_time_of_driver = ""
								if(item.settled_time_of_driver !=0){
									settled_time_of_driver = this.$my.other.totime(item.settled_time_of_driver)
								}
								//结算给司机的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
								switch(item.settle_status_of_driver){
									case '1':item.settle_status_of_driver_text="未申请";break;
									case '2':item.settle_status_of_driver_text="待打款";break;
									case '3':
										item.settle_status_of_driver_text=`打款成功 ${settled_time_of_driver}`;
										break;
									case '4':
										item.settle_status_of_driver_text=`打款失败 ${settled_time_of_truck_owner}`;
										break;
								}
								if(item.this_settled_of_truck_owner == 0){
									item.settle_status_of_truck_owner_text = ''
								}
								if(item.this_settled_of_driver ==0){
									item.settle_status_of_driver_text =''
								}
								//本次支付
								item.this_payed_text=item.this_payed+"元"

								//计算本次结给承运人的全部金额
								item.this_settled=parseFloat(item.this_settled_of_truck_owner)+parseFloat(item.this_settled_of_driver)

								//服务费率模式: 显示服务费 显示结给司机的钱
								if(item.calc_mode==1){

									//服务费
									item.service_charge_text=(item.this_payed-item.this_settled).toFixed(2)+"元"

									//结给司机的钱
									item.this_settled_text=item.this_settled+"元"
								
								//固定金额模式
								}else if(item.calc_mode==2){

									//服务费
									item.service_charge_text="--"

									//结给司机的钱
									item.this_settled_text="--"
								}

								//汇总运单数据
								if(!tord_list[item.tord_info.id]){
									tord_list[item.tord_info.id]=JSON.parse(JSON.stringify(item.tord_info))
									tord_list[item.tord_info.id].cost_info=JSON.parse(JSON.stringify(item.cost_info))
									tord_list[item.tord_info.id].driver_info=JSON.parse(JSON.stringify(item.driver_info))
									tord_list[item.tord_info.id].truck_owner_info=JSON.parse(JSON.stringify(item.truck_owner_info))
									tord_list[item.tord_info.id].payed_list=[];
								}
								//缓存运单id
								var tord_id=item.tord_info.id
								//删除一些数据
								delete(item.cost_info);
								delete(item.shipper_info);
								delete(item.truck_owner_info);
								delete(item.truck_info);
                                item.aim_addr = item.tord_info.aim_prov + item.tord_info.aim_city + item.tord_info.aim_county
                                item.case_addr = item.tord_info.case_prov + item.tord_info.case_city + item.tord_info.case_county
                                item.driver_name = item.driver_info.name
                                item.driver_tel = item.driver_info.tel
                                item.truck_plate_num = item.tord_info.truck_plate_num
								//置入支付记录数据
								tord_list[tord_id].payed_list.push(JSON.parse(JSON.stringify(item)))
                                exlce_pay_list.push(JSON.parse(JSON.stringify(item)))
							}
                           //取出最后一个id
                            if(data.list[data.list.length-1]){
                            this.id_max=data.list[data.list.length-1].id
                            }

						//置入缓存
						this.buf=this.buf.concat(exlce_pay_list)
						this.readed=this.buf.length
						this.read();
						//是否继续
						// if(this.readed<this.max){//没有达到最大数量则继续读取

						// 	this.read();
						
						// }else{//达到最大数量则开始导出

						// 	this.make_csv()
						// }
                    }
				})
			},

			//启动
			start(){
				var pay_status = this.pay_status
                if(this.type ==1){
                    //读取总数量
                    this.$my.net.req({
                        data:{
                            mod:this.mod,
                            ctr:this.ctr,
                            ...this.rull,
							pay_status
                        },
                        callback:(data)=>{
                            //如果总数量为0
                            if(data.list.length==0){
                                this.$my.other.msg({
                                    type:"warning",
                                    str:'该条件下数据为空'
                                });
                                return;
                            
                            //置入总数量
                            }else this.max=data.list.length;

                            //已读取数据
                            this.buf=[];

                            //开始读取
                            this.read();
                        }
                    })
                }else if(this.type==2){
                    this.max=this.pay_excle_liet.length
                    this.readed=this.pay_excle_liet.length
                    this.buf = this.pay_excle_liet
                    this.make_csv()
                }
			},
		}
	}
</script>